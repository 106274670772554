<template>
	<div data-component="edit-summary-messages-modal">
		<div data-element="form">
			<select-field
				v-if="showField"
				name="Select result"
				searchable
				htmlOptions
				:disabled="!!activeItem"
				:options="getDifferenceOptions"
				@input="selectDifference"
			/>
		</div>
		<div data-element="claim-parameters-list" :data-expanded="!!activeItem">
			<dynamic-table
				:columns="columns"
				:data="getSummaryMessages"
				:activeRow="getActiveIndex"
				overflow="visible"
			>
				<template #difference="{ row, cell }">
					<div data-element="difference-cell">
						<span
							data-element="contract-value"
							v-html="getContractValue(getContract)"
						/>
						<suit-symbol
							:suitName="getContractSuit(getContract)"
							:withoutPadding="true"
						/>
						<span data-element="condition">{{ cell }}</span>
					</div>
				</template>
				<template #message="{ cell }">
					<div
						data-element="message-wrapper"
						v-html="cell"
					/>
				</template>
				<template #action="{ row }">
					<div data-element="actions-cell" v-if="!activeItem">
						<btn
							text="Edit"
							size="sm"
							@click.native="handleEdit(row)"
						/>
						<btn
							text="Delete"
							colour="red"
							size="sm"
							:btnId="getDeleteOptionBtnId(row)"
							:isDisabled="getDeleteOptionBtnDisabled(row)"
							@click.native="handleDelete(row)"
						/>
					</div>
				</template>
			</dynamic-table>
			<div data-component="maximum-tricks-form" data-element="form">
				<label>Maximum tricks</label>
				<input-field
					type="number"
					v-model="maximumTricks"
					name="Maximum tricks"
					autocomplete="off"
					:min="minTricks"
					:max="maxTricks"
					:showLabel="false"
					:rules="getRules"
					size="sm"
				/>
				<btn
					text="Save"
					size="sm"
					@click.native="handleSaveMaximumTricks"
				/>
			</div>
		</div>
		<validation-observer
			v-if="getShowField"
			data-element="form"
			tag="form"
			ref="form"
			@submit.prevent="handleSave"
		>
			<select-field
				v-if="getReusableItemsVisibility"
				name="Saved messages"
				searchable
				htmlOptions
				:searchIsLoading="reusableItemsLoading"
				:options="getReusableMessagesOptions"
				@input="selectReusableMessage"
				@searchChange="handleSearchChange"
			/>
			<wysiwyg-field
				name="Message"
				type="text"
				:hasListControl="false"
				:value="getActiveItemMessage"
				@input="handleOptionInput"
			/>
			<actions
				:actions="getActions"
				:isWaiting="isWaiting"
				@actionClick="onActionClick"
			/>
		</validation-observer>
	</div>
</template>

<script>

	import InputField from '@/components/forms/InputField';
	import SelectField from '@/components/forms/SelectField';
	import DynamicTable from '@/components/ui/views/dynamicTable/DynamicTable';
	import SuitSymbol from '@/components/diagrams/SuitSymbol';
	import Btn from '@/components/ui/Btn.vue';
	import Actions from '@/components/ui/Actions';
	import api from '@/services/api';
	import actionClick from '@/mixins/actionClick';
	import invalidFormMessage from '@/mixins/invalidFormMessage';
	import { differenceOptions, defaultResultMessages, suitOptions } from '@/consts';
	import { createSuitElement } from '@/helpers';
	import sanitizeHtml from 'sanitize-html';
	import { max } from 'vee-validate/dist/rules';

	export default {
		components: {
			InputField,
			SelectField,
			DynamicTable,
			SuitSymbol,
			Btn,
			WysiwygField: () => import('@/components/forms/WysiwygField'),
			Actions
		},
		mixins: [actionClick, invalidFormMessage],
		props: {
			deal: {
				type: Object,
				default: undefined
			}
		},
		data: () => ({
			isWaiting: undefined,
			showField: true,
			columns: [
				{
					label: 'Result',
					key: 'difference'
				},
				{
					label: 'Message',
					key: 'message'
				},
				{
					label: '',
					key: 'action'
				}
			],
			activeItem: undefined,
			messages: [],
			maximumTricks: undefined,
			minTricks: 0,
			maxTricks: 13,
			reusableItemsLoading: false,
			reusableItemsSearch: '',
			reusableItems: []
		}),
		computed: {
			max () {
				return max;
			},
			getRules () {
				return {
					between: {
						min: this.minTricks,
						max: this.maxTricks
					}
				};
			},
			getContract () {
				return this.deal?.contract;
			},
			getSuitOption () {
				return suitOptions.find(option => option.value === this.getContractSuit(this.getContract));
			},
			getSummaryMessages () {
				const messages = [...this.messages];

				const ordersMap = {
					less: 0,
					makes: 1,
					max: 2
				};

				const compare = (a, b) => {
					let prev = 0;
					let next = 0;

					if (a) {
						prev = ordersMap[a.difference] || 0;
					}
					if (b) {
						next = ordersMap[b.difference] || 0;
					}

					if (prev < next) {
						return -1;
					}
					if (prev > next) {
						return 1;
					}
					return 0;
				};

				messages.sort(compare);

				return messages;
			},
			getActiveIndex () {
				return this.getSummaryMessages.findIndex(item => item.difference === this.activeItem);
			},
			getActiveItem () {
				return this.getSummaryMessages.find(item => item.difference === this.activeItem);
			},
			getDifferenceOptions () {
				return differenceOptions
					.filter(option => {
						return !this.messages
							.map(item => item.difference)
							.includes(option.value);
					})
					.map(item => {
						const option = {
							value: item.condition
						};

						const contractValue = this.getContractValue(this.getContract);

						const value = item.value ? item.value.toUpperCase() : '';

						if (this.getSuitOption) {
							const suitElement = createSuitElement(this.getSuitOption.symbol, this.getSuitOption.colour);
							option.text = `<b>${contractValue}${suitElement}${value}</b>`;
						} else {
							option.text = `<b>${contractValue}${value}</b>`;
						}

						return option;
					});
			},
			getReusableItemsVisibility () {
				return this.reusableItems.length > 0;
			},
			getReusableMessagesOptions () {
				return this.reusableItems
					.map(item => ({
						text: sanitizeHtml(item, {
							allowedTags: ['span'],
							allowedAttributes: {
								span: ['data-element', 'data-colour']
							}
						}),
						value: item
					}))
					.filter(({ text }) => text
						.toLowerCase()
						.includes(this.reusableItemsSearch.toLowerCase())
					);
			},
			getActiveItemMessage () {
				return this.getActiveItem?.message || '';
			},
			getShowField () {
				return this.getActiveItem !== undefined;
			},
			getActions () {
				return {
					primary: [
						{
							text: 'Save',
							btnId: 'save',
							type: 'submit'
						}
					],
					secondary: [
						{
							text: 'Cancel',
							actionId: 'handleEditCancel'
						}
					]
				};
			}
		},
		created () {
			this.init();
		},
		methods: {
			async init () {
				this.maximumTricks = this.deal.maximumTricks;

				await Promise.all([
					this.fetchSummaryMessages(),
					this.fetchReusableMessages()
				]);
			},
			close () {
				this.$emit('close');
			},
			getContractValue (contract) {
				if (!contract) {
					return '';
				}
				if (contract[1] === 'N') {
					return `${contract[0]}NT`;
				}
				return contract[0];
			},
			getContractSuit (contract) {
				if (!contract) {
					return '';
				}
				return contract[1];
			},
			getDeleteOptionBtnId (item) {
				return `delete-${item.difference}`;
			},
			getDeleteOptionBtnDisabled () {
				if (this.isWaiting !== undefined) {
					return this.getDeleteOptionBtnId(this.isWaiting);
				}
			},
			getDefaultMessage (condition) {
				return defaultResultMessages[condition] || '';
			},
			getConditionValue (condition) {
				return differenceOptions.find(item => item.condition === condition)?.value;
			},
			selectDifference (condition) {
				this.showField = false;
				this.activeItem = undefined;
				const message = this.getDefaultMessage(condition);
				const difference = this.getConditionValue(condition);
				this.messages = [
					...this.messages,
					{
						difference,
						message
					}
				];
				this.$nextTick(() => {
					this.showField = true;
					this.activeItem = difference;
				});
			},
			setMessageValueByDifference (value, difference) {
				const index = this.messages.findIndex(item => item.difference === difference);

				if (index !== -1) {
					this.$set(this.messages[index], 'message', value);
				}
			},
			handleOptionInput (value) {
				if (this.activeItem !== undefined) {
					this.setMessageValueByDifference(value, this.activeItem);
				}
			},
			selectReusableMessage (value) {
				const currentActiveItem = this.activeItem;
				this.activeItem = undefined;
				this.reusableItemsSearch = '';
				this.setMessageValueByDifference(value, currentActiveItem);
				this.$nextTick(() => {
					this.activeItem = currentActiveItem;
				});
			},
			handleSearchChange (query) {
				this.reusableItemsSearch = query;
			},
			async fetchSummaryMessages () {
				const response = await api.deals.getDealResultMessages({
					dealId: this.$route.params.dealId
				});

				if (response) {
					this.messages = response;
				} else {
					console.error('Something went wrong. Empty response received.');
				}
			},
			async fetchReusableMessages () {
				this.reusableItemsLoading = true;
				const response = await api.teacher.getTeacherSummaryMessages();
				if (response) {
					this.reusableItems = response;
				} else {
					console.error('Something went wrong. Empty response received.');
				}
				this.reusableItemsLoading = false;
			},
			async handleSave () {
				const valid = await this.$refs.form.validate();
				if (!valid) {
					this.showInvalidFormMessage();

				}
				this.isWaiting = 'save';
				let response = null;
				const isUpdate = !!this.getActiveItem?.id;

				if (isUpdate) {
					response = await api.deals.updateDealResultMessage({
						messageId: this.getActiveItem.id,
						message: this.getActiveItem
					});
				} else {
					response = await api.deals.createDealResultMessage({
						dealId: this.$route.params.dealId,
						message: this.getActiveItem
					});
				}

				if (response) {
					this.messages = this.messages.map(item => {
						if (item.difference === this.getActiveItem.difference) {
							return response;
						}
						return item;
					});
					this.$store.commit('ui/showNotification', {
						notification: `Successfully ${isUpdate ? 'updated' : 'saved'}.`
					});
				} else {
					console.error('Something went wrong. Empty response received.');
				}
				this.isWaiting = undefined;
				this.activeItem = undefined;
			},
			handleEdit (item) {
				this.activeItem = undefined;
				this.$nextTick(() => {
					this.activeItem = item.difference;
				});
			},
			handleEditCancel () {
				this.messages = this.messages.filter((item) => !!item.id);
				this.activeItem = undefined;
			},
			async handleDelete (item) {
				if (confirm('Are you sure?')) {
					if (item.id) {
						await api.deals.deleteDealResultMessage({
							messageId: item.id
						});
					}

					this.activeItem = undefined;
					this.messages = this.messages.filter(option => option.difference !== item.difference);
					this.$store.commit('ui/showNotification', {
						notification: 'Successfully deleted.'
					});
				}
			},
			async handleSaveMaximumTricks () {
				if (this.maximumTricks) {
					const deal = await api.deals.updateDeal({
						dealId: this.$route.params.dealId,
						deal: {
							...this.deal,
							maximumTricks: parseInt(this.maximumTricks)
						}
					});
					if (deal) {
						this.deal.maximumTricks = deal.maximumTricks;
						this.$store.commit('ui/showNotification', {
							notification: 'Maximum tricks amount has been saved.'
						});
					} else {
						console.error('Something went wrong. Empty response received.');
					}
				}
			}
		}
	};
</script>

<style lang="scss" scoped>

	[data-component='edit-summary-messages-modal'] {
		[data-component='select-field'] {
			margin-top: 0;

			::v-deep .multiselect {
				.multiselect__content-wrapper {
					.multiselect__content {
						.multiselect__element {
							.multiselect__option {
								[data-element='suit-symbol'] {
									padding: 0 rem(1);
									font-size: rem(12);
								}
							}
						}
					}
				}
			}
		}

		[data-element='claim-parameters-list'] {
			&[data-expanded='true'] {
				margin-bottom: rem(28);
			}

			::v-deep [data-component='dynamic-table'] {
				[data-element='table-column-difference'] {
					width: 110px;

					[data-element='difference-cell'] {
						display: inline-flex;
						align-items: center;
						margin: 0;
						font-weight: 700;

						[data-component='suit-symbol'] {
							margin: 0 rem(1);

							[data-component='icon'] {
								margin-top: 1px;
								width: rem(14);
								height: rem(14);
							}
						}

						[data-element="condition"] {
							text-transform: uppercase;
						}
					}
				}

				[data-element='table-column-message'] {
					width: calc(100% - 260px);

					[data-element='message-wrapper'] {
						@include rich-text-content;
						@include rich-text-suits;

						p {
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
						}
					}
				}

				[data-element='table-column-action'] {
					width: 200px;

					[data-element='actions-cell'] {
						display: flex;
						justify-content: flex-end;

						[data-component='btn'] {
							margin-left: rem(10);
						}
					}
				}
			}
		}

		[data-element='form'] {
			margin: 0;

			[data-component='wysiwyg-field'] {
				margin-top: 0;
			}

			[data-component='actions'] {
				padding: 0;
			}
		}

		[data-component='maximum-tricks-form'] {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-top: rem(10);

			label {
				margin-right: rem(10);
			}

			[data-component='input-field'] {
				width: rem(80);
				margin: 0;
			}

			[data-component='btn'] {
				margin-left: rem(10);
			}
		}
	}

</style>
